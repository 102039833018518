import React, { useState } from 'react';
import axios from 'axios';

const TokenVerificationComponent = () => {
  const [identifier, setIdentifier] = useState('');
  const [userToken, setUserToken] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const API_URL = process.env.API_URL || 'https://uat-celebrify.ro/'; // Implicit pe localhost dacă nu există variabila de mediu

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    if (!identifier || !userToken) {
      setError('Please fill in both fields.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`https://uat-celebrify.ro/api/payments/verify`, { identifier, userToken });
      setMessage(response.data.message);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message);
      } else {
        setError('Verification failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
      <h2>Email Verification</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Identifier (Email/Username):</label>
          <input
            type="text"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Verification Token:</label>
          <input
            type="text"
            value={userToken}
            onChange={(e) => setUserToken(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Verifying...' : 'Verify'}
        </button>
      </form>

      {message && <p style={{ color: 'green' }}>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default TokenVerificationComponent;
