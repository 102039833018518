import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';


function RegisterComponent() {
  const { setUser } = useContext(UserContext);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.API_URL || 'https://uat-celebrify.ro/'; // Implicit pe localhost dacă nu există variabila de mediu
  const navigate = useNavigate();  // Folosim useNavigate pentru redirecționare

  const registerUser = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`https://uat-celebrify.ro/api/payments/register`, {
        name,
        username,
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Salvăm utilizatorul și redirecționăm către pagina de verificare a tokenului
      setUser({ name, username, email });
      setLoading(false);
      navigate('/verify', { state: { email } });  // Redirecționăm cu emailul către formularul de validare
    } catch (error) {
      console.error('Eroare la înregistrare:', error);
      setError('Eroare la înregistrare.');
      setLoading(false);
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2>Înregistrare</h2>
        <input
          type="text"
          placeholder="Nume"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Parolă"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={registerUser}>Înregistrează-te</button>

        {loading && <p>Se înregistrează...</p>}
        {error && <p>{error}</p>}
      </div>
    </div>
  );
}

export default RegisterComponent;
