import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';  // Importăm contextul utilizatorului

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logoutUser } = useContext(UserContext);  // Accesăm utilizatorul și funcția de logout
  const navigate = useNavigate();  // Pentru redirecționare

  const toggleMenu = () => {
    setIsOpen(!isOpen);  // Comutăm starea meniului
  };

  const handleLogout = () => {
    logoutUser();  // Apelăm funcția de logout din context
    toggleMenu();  // Închidem meniul după logout
    navigate('/login');  // Redirecționăm către pagina de login
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <h1 className="navbar-logo">Logo</h1>

        {/* Butonul hamburger pentru mobile */}
        <div className="menu-icon" onClick={toggleMenu}>
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        {/* Links pentru navigație */}
        <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/payment" className="nav-links" onClick={toggleMenu}>Procesator de Plăți</Link>
          </li>
          <li className="nav-item">
            <Link to="/users" className="nav-links" onClick={toggleMenu}>Lista Utilizatorilor</Link>
          </li>
          
          {!user ? (
            <>
              <li className="nav-item">
                <Link to="/register" className="nav-links" onClick={toggleMenu}>Înregistrare</Link>
              </li>
              <li className="nav-item">
                <Link to="/login" className="nav-links" onClick={toggleMenu}>Autentificare</Link>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item">
                <Link to="/profile" className="nav-links" onClick={toggleMenu}>Profil Utilizator</Link>
              </li>
              <li className="nav-item">
                <Link to="/new-conversation" className="nav-links" onClick={toggleMenu}>Creează Conversație</Link>
              </li>
              <li className="nav-item">
                <Link to="/anniversaries" className="nav-links" onClick={toggleMenu}>Notificări Aniversare</Link> {/* Link nou */}
              </li>
              <li className="nav-item">
                <Link to="/login" className="nav-links" onClick={handleLogout}>Log Out</Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
