import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';  // Importăm contextul utilizatorului

function NewConversation() {
  const { user } = useContext(UserContext);  // Accesăm token-ul și ID-ul utilizatorului din context
  const [message, setMessage] = useState('');  // Mesajul introdus de utilizator
  const [responseData, setResponseData] = useState(null);  // Obiectul de răspuns
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.API_URL || 'https://uat-celebrify.ro/'; // Implicit pe localhost dacă nu există variabila de mediu
  const createConversation = async () => {
    console.log('Funcția createConversation a fost apelată.');
    
    if (!message.trim()) {
      setError('Mesajul nu poate fi gol.');
      console.log('Mesaj gol, operațiunea a fost oprită.');
      return;
    }

    if (!user || !user.token) {
      setError('Nu există un utilizator autentificat.');
      console.log('Utilizatorul nu este autentificat.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      console.log('Trimitem cererea POST către server...');
      
      // Body-ul cererii în formatul dorit
      const requestBody = {
        prompt: "Raspunsul trebuie sa fie strict in romana si succint, scurt, dar prietenos. Nu raspunde la nimic altceva in afara de idei de cadouri despre cei dragi. Daca vor altceva spui ca din pacate, nu ai fost antrenat pentru altceva." + message,
      };

      console.log('Corpul cererii POST:', requestBody);

      // Trimitem cererea POST cu token-ul de autentificare
      const response = await axios.post(`https://uat-celebrify.ro/api/llm/gemini`, requestBody, {
        headers: {
          'Authorization': `Bearer ${user.token}`,  // Trimitem token-ul în header-ul Authorization
          'Content-Type': 'application/json',
        },
      });

      console.log('Răspuns primit de la server:', response);

      if (response && response.data) {
        console.log('Răspunsul de la server conține date:', response.data);
      } else {
        console.log('Răspunsul de la server NU conține date.');
      }

      // Setăm răspunsul de la server în starea locală
      setResponseData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Eroare la crearea conversației:', error);
      if (error.response) {
        console.error('Detalii răspuns eroare:', error.response);
      } else {
        console.error('Nu am primit un răspuns valid de la server.');
      }
      setError('Eroare la crearea conversației.');
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Creează o nouă conversație</h1>
      <div>
        <textarea
          placeholder="Scrie mesajul..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="4"
          cols="50"
        />
      </div>
      <button onClick={createConversation}>Trimite Mesaj</button>

      {loading && <p>Se creează conversația...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Afișăm răspunsul de la server dacă există */}
      {responseData && (
        <div>
          <h3>Conversație Creată cu Succes</h3>
          <p><strong>Întrebare:</strong> {message}</p>
          <div className="typing-effect">
            <p><strong>Răspuns:</strong> {responseData.text}</p>  {/* Afișăm răspunsul direct */}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewConversation;
