import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';

const API_URL = process.env.API_URL || 'https://uat-celebrify.ro'; // URL-ul API-ului
const NOTIFICATIONS_SENT_KEY = 'notificationsSent'; // Cheie pentru localStorage

function AnniversaryNotificationPage() {
  const { user } = useContext(UserContext); // Contextul utilizatorului
  const [favorites, setFavorites] = useState([]); // Persoane favorite
  const [loading, setLoading] = useState(true); // Status încărcare
  const [error, setError] = useState(null); // Erori
  const [notificationLog, setNotificationLog] = useState([]); // Log notificări

  useEffect(() => {
    if (user && user.token) {
      fetchUserFavorites();
    }
  }, [user]);

  // Funcție pentru salvarea notificărilor în localStorage
  const saveNotificationsToLocalStorage = (notifications) => {
    localStorage.setItem(NOTIFICATIONS_SENT_KEY, JSON.stringify(notifications));
  };

  // Funcție pentru încărcarea notificărilor din localStorage
  const loadNotificationsFromLocalStorage = () => {
    const storedNotifications = localStorage.getItem(NOTIFICATIONS_SENT_KEY);
    return storedNotifications ? JSON.parse(storedNotifications) : {};
  };

  // Fetch utilizatori și persoane favorite
  const fetchUserFavorites = async () => {
    try {
      console.log('[Fetching] Obținem datele utilizatorilor...');
      const response = await axios.get(`${API_URL}/api/ai/users`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      const allUsersData = response.data;
      console.log('[Response] Date utilizatori:', allUsersData);

      allUsersData.forEach((userData) => {
        if (Array.isArray(userData.favorite_people)) {
          const favoritePeopleArray = userData.favorite_people.map((person) => ({
            ...person,
            addedBy: userData.displayName, // Adăugăm numele utilizatorului
            userId: userData.uid, // Adăugăm UID-ul utilizatorului
            fcmToken: userData.fcmToken, // Adăugăm FCM token-ul
          }));

          checkForAnniversaries(favoritePeopleArray, userData.uid);
          setFavorites((prevFavorites) => [...prevFavorites, ...favoritePeopleArray]);
        } else {
          console.log(`[Info] Utilizatorul ${userData.displayName} nu are persoane favorite.`);
        }
      });

      setLoading(false);
    } catch (error) {
      console.error('[Error] Eroare la obținerea datelor:', error);
      setError('Eroare la obținerea datelor din Firebase: ' + error.message);
      setLoading(false);
    }
  };

  // Verificare aniversări și trimitere notificări
  const checkForAnniversaries = (favoritePeople, userId) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const todayDateKey = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

    let notificationsSent = loadNotificationsFromLocalStorage();

    favoritePeople.forEach((person) => {
      if (person.basic_info && person.basic_info.birthdate) {
        const birthdate = new Date(person.basic_info.birthdate);
        const birthdayThisYear = new Date(currentYear, birthdate.getMonth(), birthdate.getDate());
        const daysUntilBirthday = Math.ceil((birthdayThisYear - today) / (1000 * 60 * 60 * 24));

        console.log(`[Check] ${person.basic_info.firstName} ${person.basic_info.lastName}: ${daysUntilBirthday} zile până la aniversare.`);

        if ([7, 3, 2, 1].includes(daysUntilBirthday)) {
          const personId = `${person.basic_info.firstName}_${person.basic_info.lastName}`;

          if (!notificationsSent[todayDateKey]) {
            notificationsSent[todayDateKey] = {};
          }

          if (!notificationsSent[todayDateKey][personId]) {
            sendNotification(person, userId, daysUntilBirthday);
            notificationsSent[todayDateKey][personId] = true;
            saveNotificationsToLocalStorage(notificationsSent);
          } else {
            console.log(`[Info] Notificare deja trimisă pentru ${person.basic_info.firstName} ${person.basic_info.lastName}.`);
          }
        }
      }
    });
  };

  // Funcție pentru trimiterea notificărilor
  const sendNotification = (person, userId, daysUntilBirthday) => {
    const { firstName, lastName } = person.basic_info;
    const title = `Urmează aniversarea lui ${firstName} ${lastName}`;
    const body = `Pregătește un cadou perfect pentru ${firstName}! Explorează selecția noastră de cadouri speciale.`;

    console.log(`[Sending Notification] Pentru ${firstName} ${lastName}, userId: ${userId}`);

    axios
      .post(
        `${API_URL}/api/push/send-notification`,
        {
          token: person.fcmToken,
          title,
          body,
          userId,
          daysUntilBirthday,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      )
      .then(() => {
        setNotificationLog((prevLog) => [
          ...prevLog,
          `Notificare trimisă pentru ${firstName} ${lastName}, cu ${daysUntilBirthday} zile înainte.`,
        ]);
      })
      .catch((error) => {
        setNotificationLog((prevLog) => [
          ...prevLog,
          `Eroare la trimiterea notificării pentru ${firstName} ${lastName}: ${error.message}`,
        ]);
        console.error(`[Error] Eroare la trimiterea notificării pentru ${firstName} ${lastName}:`, error);
      });
  };

  if (loading) return <div>Se încarcă...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="anniversary-page">
      <h1>Persoanele favorite ale utilizatorilor</h1>
      {favorites.length === 0 ? (
        <p>Nu există persoane favorite adăugate.</p>
      ) : (
        <ul>
          {favorites.map((person, index) => (
            <li key={index}>
              <h2>{person.basic_info?.firstName} {person.basic_info?.lastName}</h2>
              <p>Adăugat de: {person.addedBy}</p>
              <p>Data nașterii: {person.basic_info?.birthdate}</p>
            </li>
          ))}
        </ul>
      )}
      <h2>Log notificări</h2>
      <ul>
        {notificationLog.map((logMessage, index) => (
          <li key={index}>{logMessage}</li>
        ))}
      </ul>
    </div>
  );
}

export default AnniversaryNotificationPage;
