import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext'; // Importăm contextul utilizatorului
import { useNavigate } from 'react-router-dom';

function UsersPage() {
  const { user } = useContext(UserContext); // Accesăm token-ul din context
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Pentru redirecționare
  const API_URL = process.env.API_URL || 'https://uat-celebrify.ro'; // URL-ul API-ului

  const [notificationLog, setNotificationLog] = useState([]); // Log notificări

  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login'); // Redirecționare dacă utilizatorul nu este autentificat
    } else {
      fetchUsers(); // Fetch utilizatori
    }
  }, [user, navigate]);

  const fetchUsers = async () => {
    if (!user || !user.token) {
      setError('Token JWT lipsește. Vă rugăm să vă autentificați din nou.');
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/ai/users`, {
        headers: {
          Authorization: `Bearer ${user.token}`, // Token JWT
        },
      });
      console.log('[Fetch Users] Response:', response.data);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('[Fetch Users] Error:', error.message);
      setError('Eroare la obținerea datelor din Firebase: ' + error.message);
      setLoading(false);
    }
  };

  const sendNotification = async (fcmToken, title, body, userId, firstName, lastName) => {
    try {
      console.log(`[Notification] Trimitere notificare pentru utilizator ${userId}`);
      console.log(`[Notification] FCM Token: ${fcmToken}, FirstName: ${firstName}, LastName: ${lastName}`);

      const response = await axios.post(
        `${API_URL}/api/push/send-notification`,
        {
          token: fcmToken,
          title,
          body,
          userId,
          firstName,
          lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Token JWT
          },
        }
      );

      // Log notificare cu succes
      setNotificationLog((prevLog) => [
        ...prevLog,
        `Notificare trimisă cu succes pentru ${userId}: ${title}`,
      ]);
      alert('Notificare trimisă cu succes!');
    } catch (error) {
      // Log eroare
      console.error(`[Notification Error] Error for userId ${userId}:`, error.message);
      setNotificationLog((prevLog) => [
        ...prevLog,
        `Eroare la trimiterea notificării pentru ${userId}: ${error.message}`,
      ]);
      alert('Eroare la trimiterea notificării: ' + error.message);
    }
  };

  if (loading) {
    return <div className="loading-message">Se încarcă...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="users-container">
      <h1>Lista utilizatorilor</h1>
      {users.length === 0 ? (
        <p>Nu s-au găsit utilizatori.</p>
      ) : (
        <table className="users-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nume complet</th>
              <th>Email</th>
              <th>Adresă</th>
              <th>Telefon</th>
              <th>Token FCM</th>
              <th>Ultimul login</th>
              <th>Trimite notificare</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.uid}>
                <td>{user.uid}</td>
                <td>{user.displayName || 'N/A'}</td>
                <td>{user.email || 'N/A'}</td>
                <td>{user.address || 'N/A'}</td>
                <td>{user.phone || 'N/A'}</td>
                <td>{user.fcmToken ? user.fcmToken.slice(0, 10) : 'N/A'}</td>
                <td>{user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'N/A'}</td>
                <td>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      const title = e.target.elements.title.value;
                      const body = e.target.elements.body.value;
                      sendNotification(
                        user.fcmToken,
                        title,
                        body,
                        user.uid,
                        user.displayName.split(' ')[0] || 'N/A',
                        user.displayName.split(' ')[1] || 'N/A'
                      );
                    }}
                  >
                    <input
                      type="text"
                      name="title"
                      placeholder="Titlul notificării"
                      required
                    />
                    <input
                      type="text"
                      name="body"
                      placeholder="Mesajul notificării"
                      required
                    />
                    <button type="submit">Trimite</button>
                  </form>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <h2>Log notificări</h2>
      <ul>
        {notificationLog.map((logMessage, index) => (
          <li key={index}>{logMessage}</li>
        ))}
      </ul>
    </div>
  );
}

export default UsersPage;
