import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // La montarea componentei, verificăm dacă există un token în Local Storage
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const identifier = localStorage.getItem('identifier');

    if (token && userId && identifier) {
      // Setăm utilizatorul din Local Storage
      setUser({ token, userId, identifier });
    }
  }, []);

  // Funcția de logout
  const logoutUser = () => {
    // Ștergem token-ul și datele utilizatorului din Local Storage
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('identifier');

    // Resetăm utilizatorul în context
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, setUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};
