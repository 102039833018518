import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

function LoginComponent() {
  const { setUser } = useContext(UserContext);  // Folosim contextul pentru a salva utilizatorul și token-ul
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();  // Inițializăm navigate
  const API_URL = process.env.API_URL || 'https://uat-celebrify.ro/'; // Implicit pe localhost dacă nu există variabila de mediu
  const loginUser = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`https://uat-celebrify.ro/api/payments/login`, {
        identifier,
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const { userId, token } = response.data;
  
      // Stocăm token-ul și detaliile utilizatorului în Local Storage
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.setItem('identifier', identifier);
  
      // Setăm utilizatorul în context
      setUser({ identifier, userId, token });
      
      setLoading(false);
      navigate('/profile');  // Redirecționăm utilizatorul la profil sau altă pagină
    } catch (error) {
      console.error('Eroare la autentificare:', error);
      setError('Eroare la autentificare. Verificați datele introduse.');
      setLoading(false);
    }
  };
  

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Login</h1>
        <div>
          <input
            type="text"
            placeholder="Email sau Username"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder="Parolă"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button onClick={loginUser}>Autentificare</button>
        {loading && <p>Se autentifică...</p>}
        {error && <p>{error}</p>}
      </div>
    </div>
  );
}

export default LoginComponent;
