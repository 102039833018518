import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

function ProfileComponent() {
  const { user } = useContext(UserContext);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Profil Utilizator</h1>
      {user ? (
        <div>
          <p>ID Utilizator: {user.userId}</p>
          <p>Email sau Username: {user.identifier}</p>
          <p>Token: {user.token}</p>  {/* Afișăm token-ul */}
        </div>
      ) : (
        <p>Nu ești autentificat.</p>
      )}
    </div>
  );
}

export default ProfileComponent;
