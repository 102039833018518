import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { UserContext } from '../contexts/UserContext';  // Importăm contextul utilizatorului
import { useNavigate } from 'react-router-dom';

function PaymentProcessor() {
  const { user } = useContext(UserContext);  // Accesăm token-ul și email-ul din context
  const [message, setMessage] = useState(''); // Mesajul de succes/eroare
  const [celebrifyKey, setCelebrifyKey] = useState(''); // Stocăm token-ul de la Celebrify
  const navigate = useNavigate();  // Pentru redirecționare
  const API_URL = process.env.API_URL || 'https://uat-celebrify.ro'; // Implicit pe localhost dacă nu există variabila de mediu

  // Verificăm autentificarea la montarea componentei
  useEffect(() => {
    if (!user || !user.token) {
      navigate('/login'); // Redirecționare dacă utilizatorul nu este autentificat
    }
  }, [user, navigate]);

  // Funcția pentru gestionarea erorilor și afișarea mesajelor
  const handleError = (error, defaultMessage) => {
    console.error(error);
    if (error.response && error.response.data) {
      setMessage(`Eroare: ${error.response.data.message || defaultMessage}`);
    } else {
      setMessage(defaultMessage);
    }
  };

  console.log(user);

  // Funcția pentru a obține token-ul X-CELEBRIFY-KEY
  const fetchCelebrifyKey = async () => {
    setMessage(''); // Resetăm mesajul anterior
    if (!user || !user.identifier) {
      setMessage('Nu există email sau username.');
      return;
    }

    try {
      const response = await axios.post(`https://uat-celebrify.ro/api/payments/generate-api-key`, {
        email: user.identifier,  // Folosim `user.identifier` pentru email sau username
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`  // Trimitem JWT-ul din context
        }
      });

      const apiKey = response.data.apiKey;
      if (apiKey) {
        setCelebrifyKey(apiKey);
        setMessage('Tokenul a fost obținut cu succes.');
      } else {
        setMessage('Nu am primit un token de la server.');
      }
    } catch (error) {
      handleError(error, 'Eroare la obținerea token-ului. Verifică consola pentru detalii.');
    }
  };

  // Funcția pentru a apela endpoint-ul Stripe și a crea un intent de plată
  const handlePayment = async () => {
    setMessage(''); // Resetăm mesajul anterior
    if (!celebrifyKey) {
      setMessage('Trebuie să obții mai întâi token-ul X-CELEBRIFY-KEY.');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/payments/create-payment-intent`, {
        amount: 1000,  // Exemplu de sumă (1000 RON)
        currency: 'ron'
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CELEBRIFY-KEY': celebrifyKey,  // Trimiterea token-ului obținut
          'Authorization': `Bearer ${user.token}`  // Trimitem și JWT-ul din context
        },
        timeout: 5000 // Setăm un timeout de 5 secunde
      });

      if (response.data.clientSecret) {
        setMessage('Succes! Plata a fost creată. Functioneaza.');
      } else {
        setMessage('Eroare! Nu am primit un clientSecret.');
      }
    } catch (error) {
      handleError(error, 'Eroare la procesarea plății. Verifică consola pentru detalii.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Procesator de Plăți</h1>

      {/* Buton pentru obținerea token-ului */}
      <button onClick={fetchCelebrifyKey} disabled={celebrifyKey !== ''}>
        {celebrifyKey !== '' ? 'Token obținut' : 'Obține token-ul pentru plată'}
      </button>

      <br /><br />

      {/* Buton pentru efectuarea plății */}
      <button onClick={handlePayment} disabled={!celebrifyKey}>
        Efectuează Plată
      </button>

      {/* Afișăm mesajul */}
      {message && <p>{message}</p>}
    </div>
  );
}

export default PaymentProcessor;
