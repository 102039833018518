import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaymentProcessor from './pages/PaymentProcessor';
import UsersPage from './pages/UsersPage';
import AnniversaryNotificationPage from './pages/AnniversaryNotificationPage';
import RegisterComponent from './components/RegisterComponent';
import ProfileComponent from './components/ProfileComponent';
import LoginComponent from './components/LoginComponent';
import NewConversationComponent from './components/NewConversationComponent';  
import { UserProvider } from './contexts/UserContext';
import Navbar from './components/NavbarComponent';  // Importăm Navbar
import TokenVerificationComponent from './components/TokenVerificationComponent';

function App() {
  return (
    <UserProvider>
      <Router>
        <Navbar />  {/* Adăugăm componenta Navbar */}
        <div>
          {/* Definirea rutelor */}
          <Routes>
            <Route path="/payment" element={<PaymentProcessor />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/register" element={<RegisterComponent />} />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="/profile" element={<ProfileComponent />} />
            <Route path="/new-conversation" element={<NewConversationComponent />} />
            {/* Scoatem `PrivateRoute` pentru ca ruta să nu mai fie protejată */}
            <Route path="/verify" element={<TokenVerificationComponent />} />
            <Route path="/anniversaries" element={<AnniversaryNotificationPage />} />
            <Route path="/" element={<PaymentProcessor />} />  {/* Ruta implicită */}
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
